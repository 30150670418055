"use client";

//import Link from "next/link";
import { usePathname } from "next/navigation";

const NavLink = ({ href, exact, children, ...props }) => {
  const pathname = usePathname();
  const active = " text-primary";
  const isActive = exact ? pathname === href : pathname.startsWith(href);

  if (isActive) {
    if (props.activeclassname) {
      props.className += props.activeclassname;
    } else {
      props.className += active;
    }
  }

  return (
    <a href={href} {...props}>
      {children}
    </a>
  );
};
export default NavLink;
